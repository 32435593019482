// Generated by Framer (716dd6f)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["HxF2tai9x"];

const variantClassNames = {HxF2tai9x: "framer-v-1lnctgw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "HxF2tai9x", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "HxF2tai9x", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-s9qls", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1lnctgw", className)} data-framer-name={"bolt"} layoutDependency={layoutDependency} layoutId={"HxF2tai9x"} ref={ref} style={{...style}} transition={transition}><SVG className={"framer-ayct2w"} data-framer-name={"icon"} fill={"rgba(0,0,0,1)"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"m2BQ9tnM0"} svg={"<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<g clip-path=\"url(#clip0_1_4013)\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.2317 1.35987C13.8635 0.601694 15.0952 1.11999 14.9948 2.10179L14.2893 9.00005H20C20.8478 9.00005 21.311 9.98891 20.7682 10.6402L10.7682 22.6402C10.1364 23.3984 8.90474 22.8801 9.00515 21.8983L9.71065 15.0001H3.99996C3.15212 15.0001 2.68896 14.0112 3.23174 13.3599L13.2317 1.35987Z\" fill=\"white\"/>\n</g>\n<defs>\n<clipPath id=\"clip0_1_4013\">\n<rect width=\"24\" height=\"24\" fill=\"white\"/>\n</clipPath>\n</defs>\n</svg>\n"} transition={transition} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-s9qls [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-s9qls * { box-sizing: border-box; }", ".framer-s9qls .framer-1hvvixg { display: block; }", ".framer-s9qls .framer-1lnctgw { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-s9qls .framer-ayct2w { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerAdHey112M: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerAdHey112M;

FramerAdHey112M.displayName = "button-icon";

FramerAdHey112M.defaultProps = {height: 24, width: 24};

addFonts(FramerAdHey112M, [])